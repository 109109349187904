import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'Login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/Home',
    redirect: 'Home',
    component: () => import('../views/Dashboard/Home.vue'),
    children: [
      {
        path: '/Inicio',
        name: 'Inicio',
        component: () => import('../views/Dashboard/Inicio.vue')
      },
      {
        path: '/Campana',
        name: 'Campana',
        component: () => import('../views/Dashboard/Campaña.vue')
      },
      {
        path: '/Senior',
        name: 'Senior',
        component: () => import('../views/Dashboard/Senior.vue')
      },
      {
        path: '/Junior',
        name: 'Junior',
        component: () => import('../views/Dashboard/Junior.vue')
      },
      {
        path: '/Catalogos',
        name: 'Catalogos',
        component: () => import('../views/Dashboard/Catalogos.vue')
      },
      {
        path: '/Proveedores',
        name: 'Proveedores',
        component: () => import('../views/Dashboard/Proveedores.vue')
      },
      {
        path: '/Rubros',
        name: 'Rubros',
        component: () => import('../views/Dashboard/Rubros.vue')
      },
      {
        path: '/RSenior',
        name: 'RSenior',
        component: () => import('../views/Reportes/Senior.vue')
      },
      {
        path: '/RJunior',
        name: 'RJunior',
        component: () => import('../views/Reportes/Junior.vue')
      },
      {
        path: '/RProductos',
        name: 'RProductos',
        component: () => import('../views/Reportes/Productos.vue')
      },
      {
        path: '/RRubros',
        name: 'RRubros',
        component: () => import('../views/Reportes/Rubros.vue')
      },
      {
        path: '/RProveedor',
        name: 'RProveedor',
        component: () => import('../views/Reportes/Proveedor.vue')
      },
      {
        path: '/ROrden',
        name: 'ROrden',
        component: () => import('../views/Reportes/Orden.vue')
      },
      {
        path: '/RCostos',
        name: 'RCostos',
        component: () => import('../views/Reportes/Costos.vue')
      },
      {
        path: '/ListaProductos',
        name: 'ListaProductos',
        component: () => import('../views/Dashboard/Productos.vue')
      },
      {
        path: '/ListaBultos',
        name: 'ListaBultos',
        component: () => import('../views/Reportes/Bultos.vue')
      },
      {
        path: '/Pedido',
        name: 'Pedido',
        component: () => import('../views/Dashboard/Pedido.vue')
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
